<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="3"></nav-header>

    <!-- 內容頁 -->
    <div class="app-container hearingExam">
      <div class="box">
        <!-- 頭部准考證號 -->
        <exam-information></exam-information>

        <!-- 实测开始页面 -->
        <div v-if="introOrAnswer == 'intro'">
          <topic-hearing-intro ref="topicIntro" @quizStarts="quizStarts"></topic-hearing-intro>
        </div>

        <!-- 答题页面 -->
        <div v-if="introOrAnswer == 'answer'">
          <!-- 考題內容 -->
          <div class="box-content">
            <!-- 试题范例 -->
            <topic-hearing-test v-if="topicType == 0" ref="topicTest" :dataForm="testData" @playChange="playChange"></topic-hearing-test>
            <!-- 萬用題型 -->
            <topic-hearing-answer v-if="topicType == 1" ref="topicAnswer" :dataForm="topicData" :partTitle="partTitle" @playChange="playChange" @answerChange="answerChange"></topic-hearing-answer>
          </div>
          <!-- 題目切換 -->
          <topic-hearing-progress
            ref="topicProgress"
            v-if="dataList && dataList.length"
            :list="dataList"
            @progressChange="progressChange"
          ></topic-hearing-progress>
        </div>
      </div>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>

    <!-- 分享按钮弹窗 -->
    <share-popup v-model="shareShow"></share-popup>
    
    <!-- 开考彈窗 -->
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="app-popup hearingExam-popup" v-if="popupShow">
        <div class="button">
          <button @click="popupClick" :class="popupTitle == '開始聽力測驗' ? '' : 'button-continue'">
            {{ popupTitle }}
            <img src="@/assets/image/examination/arrow.png" alt="">
          </button>
          <div class="button-finger">
            <img src="@/assets/image/examination/finger.png" alt="">
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from "vuex"
import { questionList, questionBegins, questionAnswer, questionSaveExam } from "@/request/api"
import TopicHearingIntro from "./components/Topic/TopicHearingIntro.vue"
import TopicHearingTest from "./components/Topic/TopicHearingTest.vue"
import TopicHearingAnswer from "./components/Topic/TopicHearingAnswer.vue"
import TopicHearingProgress from "./components/Topic/TopicHearingProgress.vue"
import ExamInformation from "./components/ExamInformation.vue"
import SharePopup from '@/components/SharePopup.vue'
export default {
  name: 'HearingExam',
  components: {
    TopicHearingIntro,
    TopicHearingTest,
    TopicHearingAnswer,
    TopicHearingProgress,
    ExamInformation,
    SharePopup
  },
  data() {
    return {
      // 分享荧幕弹窗
      shareShow: false,
      // 開始聽力測驗按鈕
      popupShow: true,
      popupBtnStatus: false,
      popupTitle: '開始聽力測驗',

      // 判断是介绍还是答题
      introOrAnswer: 'intro',
      
      // 题目类型  1=聽力測驗，2=閱讀測驗，3=寫作測驗，4=口說能力測驗
      type: 1,
      // 数据列表
      dataList: [],

      // 题目类型
      topicType: 0,

      // 部位选中
      partActive: 0,
      // 题目选中
      topicActive: 0,
      // 範例選中
      testActive: 0,
      // 是否是範例
      testStatus: true,
      
      // 當前部分標題
      partTitle: "",
      // 当前题目对象
      topicData: {},
      // 当前示例对象
      testData: {},
      
      // 考试倒计时
      reciprocalTime: 0,
      // 考试定时器
      examTimerOut: null,
    }
  },
  computed: {
    ...mapGetters([
      'examType',
      'examStatus',
      'examIsLast',
      'examCountdown',
      'examAuestionSort',
      'examRemainingTime',
      'problemPopupStatus',
      'shareType'
    ]),
  },
  watch: {
    // 監聽問題回報彈窗 同步關閉考試倒計時
    problemPopupStatus(val) {
      if (val) {
        // 清除定时器
        clearInterval(this.examTimerOut);
        this.examTimerOut = null;
      } else {
        this.examCountdownHandle()
      }
    },
    // 监听agora分享状态 弹出强制分享弹窗
    shareType: {
      handler(val) {
        if (val != 1) {
          this.shareShow = true
        }
      },
      immediate: true
    }
  },
  created() {
    this.init()
  },
  methods: {
    // 页面初始化 执行判断
    async init() {
      const examData = await this.$store.dispatch('exam/getLoadExam')
      // 拿到倒計時剩餘時間
      this.reciprocalTime = examData.remaining_time
      // examType=-1 考试未开始 显示题型介绍
      // examType=1 考试進行中 显示題目
      if (this.examType == -1) {
        this.popupShow = true
        this.popupTitle = '開始聽力測驗'
        this.introOrAnswer = 'intro'
      } else if (this.examType == 1) {
        // 獲取題目列表
        this.getList()
        this.popupShow = true
        this.popupTitle = '繼續聽力測驗'
        if (this.examAuestionSort == 0) {
          this.introOrAnswer = 'intro'
        } else {
          this.introOrAnswer = 'answer'
        }
      }
    },

    // 考试倒计时 開啟
    examCountdownHandle() {
      if (this.reciprocalTime > 0) {
        // 时间递减
        this.reciprocalTime--
        // 时间赋值
        store.commit('exam/SET_EXAMCOUNTDOWN', this.reciprocalTime)
        store.commit('exam/SET_EXAMREMAININGTIME', this.reciprocalTime)
        // 重复调用倒计时
        this.examTimerOut = window.setTimeout(this.examCountdownHandle, 1000)
      } else {
        // 倒计时结束  强行交卷跳转下一个考试
        this.$refs.topicProgress.examOverOpen()
      }
    },

    // 获取题目列表
    getList() {
      questionList({
        type: this.type
      }).then(res => {
        this.dataList = this.dataProcessing(res.data.list)
        this.testData = this.dataList?.[this.partActive]?.example

        // 判斷之前的答題進度
        if (this.examAuestionSort && this.examAuestionSort.length > 3) {
          let [val1, val2, val3] = this.examAuestionSort.split('&')
          this.partActive = val1
          this.topicActive = val2
          this.testActive = val3
          // 設置範例顯示狀態  0不顯示  反之則顯示
          this.testStatus = this.testActive == 0 ? false : true
        } else {
          this.testActive = 0
          this.partActive = 0
          this.topicActive = 0
          // 默認是顯示的
          this.testStatus = true
        }
        
        // this.testStatus 為ture的時候顯示範例  反之就是正常的題目
        if (this.testStatus) {
          // 切换题目类型
          this.topicType = 0
          // 给当前选中示例赋值
          this.testData = this.dataList?.[this.testActive]?.example
        } else {
          // 切换题目类型
          this.topicType = 1
          // 给当前选中题目赋值
          this.topicData = this.dataList?.[this.partActive]?.question_group?.[this.topicActive]
          this.partTitle = this.dataList?.[this.partActive]?.part_title
        }
      })
    },
    // 数据处理 加入答题状态用于作答进度
    dataProcessing(list) {
      let totalProgress = 0
      let arr = list.map((item, index) => {
        item.isAnswer = 0
        item.question_group = item.question_group.map((items, indexs) => {
          // 总题目长度
          totalProgress++
          // 当前题目下标
          items.progressNum = totalProgress
          // 是否已答题
          let num = 0
          items.question.forEach(i => {
            if (i.answer.answer) {
              num++
            } else {
              num--
            }
          })
          items.isAnswer = num == items.question.length ? 1 : 0
          return items
        })
        let num = 0
        item.question_group.forEach(i => {
          if (i.isAnswer == 1) {
            num++
          } else {
            num--
          }
        })
        item.isAnswer = num == item.question_group.length ? 1 : 0
        return item
      })
      return arr
    },
    // 进度变动
    async progressChange(val) {
      const testActive = val.testActive
      // 获取长度
      const arr = val.topicActive?.split('-')
      // 拿到部位 题目下标
      const [ partActive, topicActive ] = arr
      // 给部位选中赋值
      this.partActive = partActive
      // 给题目选中赋值
      this.topicActive = topicActive

      // testActive == 0就是正常的切换题目  不等于0就是跳入范例
      if (testActive != 0) {
        // 切换题目类型
        this.topicType = 0
        // 给当前选中示例赋值
        this.testData = this.dataList?.[testActive]?.example
        // 自動播放音頻
        if (val.butStatus == 1) {
          this.$nextTick(() => {
            this.$refs.topicTest.init()
          })
        }
      } else {
        // 切换题目类型
        this.topicType = 1
        const topicData = this.dataList?.[partActive]?.question_group?.[topicActive]
        this.partTitle = this.dataList?.[partActive]?.part_title
        // 给当前选中题目赋值
        this.topicData = topicData
        // 自動播放音頻
        if (val.butStatus == 1) {
          this.$nextTick(() => {
            // 初始化音頻
            this.$refs.topicAnswer.audioInit()
            // 播放音頻
            this.$nextTick(() => {
              this.$refs.topicAnswer.audioPlay()
            })
          })
        }
      }

      // 滾動條置頂
      if (this.$refs.topicAnswer) this.$refs.topicAnswer.toRoll()

      // 判断是否显示提交按钮
      const pratLength = this.dataList.length - 1
      const topicLengtn = this.dataList[partActive].question_group.length - 1
      let is_last = this.examIsLast
      if (partActive == pratLength && topicActive == topicLengtn) {
        is_last = 1
        store.commit('exam/SET_EXAMISLAST', 1)
      }

      // 提交當前的考試進程
      await questionSaveExam({
        question_sort: `${partActive}&${topicActive}&${testActive}`,
        remaining_time: this.examRemainingTime,
        is_last: is_last
      }).then(() => {})
    },

    // 彈窗按鈕點擊
    popupClick() {
      if (this.popupTitle == '開始聽力測驗') {
        if (this.popupBtnStatus == true) {
          return false
        }
        this.popupBtnStatus = true
        questionBegins({
          type: this.type
        }).then(() => {
          // 關閉彈窗
          this.popupShow = false
          // 獲取題目列表
          this.getList()
          // 開啟倒計時
          this.examCountdownHandle()
          // 判断 题目类型为0  就播放题型介绍
          if (this.topicType == 0) {
            this.$nextTick(() => {
              this.$refs.topicIntro.init()
            })
          }
        }).finally(() => {
          this.popupBtnStatus = false
        })
      } else {
        // 播放题型介绍
        if (this.introOrAnswer == 'intro') {
          this.$nextTick(() => {
            this.$refs.topicIntro.init()
          })
        } else {
          if (this.examIsLast == 0) {
            if (this.testStatus) {
              // 自動播放音頻
              this.$nextTick(() => {
                this.$refs.topicTest.init()
              })
            } else {
              // 自動播放音頻
              this.$nextTick(() => {
                // 初始化音頻
                this.$refs.topicAnswer.audioInit()
                // 播放音頻
                this.$nextTick(() => {
                  this.$refs.topicAnswer.audioPlay()
                })
              })
            }
          }
        }
        // 關閉彈窗
        this.popupShow = false
        // 開啟倒計時
        this.examCountdownHandle()
      }
    },

    // 測驗開始
    async quizStarts() {
      this.introOrAnswer = 'answer'
      this.topicType = 0
      
      // 提交當前的考試進程
      await questionSaveExam({
        question_sort: '1',
        remaining_time: this.examRemainingTime,
        is_last: 0
      }).then(() => {})

      this.$nextTick(() => {
        this.$refs.topicTest.init()
      })
    },

    // 題目播放完成 切換下一題 
    playChange() {
      this.$refs.topicProgress.next()
    },
    // 答案賦值
    answerChange(val) {
      const data = this.dataList?.[this.partActive]?.question_group?.[this.topicActive]
      if (data.group_type == 1) { // 1單體形式  2題組形式
        this.dataList[this.partActive].question_group[this.topicActive].question[0].answer.answer = val
        this.dataList = this.dataProcessing(this.dataList)
      }

      // 每次变动答案都提交
      this.answerSubmit()
    },
    
    // 提交答案
    async answerSubmit() {
      await questionAnswer({
        type: this.type,
        list: this.dataList
      }).then(() => {})
    },
    

    // 这个事件只有在鼠标真正和浏览器有了交互，再刷新或者关闭时才会触发, 浏览器事件会弹框确认用户是否要离开页面
    beforeunloadHandler(e) {
      // console.log('頁面刷新時 在此處做處理')

      // 請求路徑
      let baseURL = ''
      if (process.env.NODE_ENV === 'development') {
        baseURL = process.env.VUE_APP_BASEURL
      } else {
        baseURL = window.location.protocol + "//" + window.location.host
      }
      const url = baseURL + '/api/question/answer_questions'
      
      // // blob傳參
      // const data = {
      //   type: this.type,
      //   list: this.dataList
      // }
      // const blob = new Blob([JSON.stringify(data), {
      //   type: 'application/x-www-form-urlencoded',
      // }]);
      // navigator.sendBeacon(url, blob);

      // // formdata傳參
      // const formData = new FormData();
      // formData.append('type', this.type)
      // formData.append('list', JSON.stringify(this.dataList))
      // navigator.sendBeacon(url, formData)

      e = e || window.event
      if (e) {
        e.returnValue = '关闭提示'
      }
      return '关闭提示'
    }
  },
  beforeDestroy() {
    // 清除定时器
    clearInterval(this.examTimerOut);
    this.examTimerOut = null;
  },
  // mounted() {
  //   window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
  // destroyed() {
  //   window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // },
}
</script>

<style lang="less" scoped>
.hearingExam {
  .box {
    margin: 12px auto;
    width: 1320px;
    .box-content {
      margin: 12px 0;
      padding: 20px 30px 10px;
      height: calc(100vh - 362px); // 計算出除了內容 減去其他的高度
      min-height: 536px;
      background: #FFFFFF;
      border-radius: 8px;
      position: relative;
    }
  }
}
.hearingExam-popup {
  .button {
    position: relative;
    button {
      cursor: pointer;
      width: 633px;
      height: 160px;
      background: #50CE8E;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 80px;
      color: #FFFFFF;
      border: none;
      img {
        margin-left: 13px;
        width: 60px;
        height: 60px;
      }
    }
    .button-continue {
      background: #0C4FA2;
    }
    .button-finger {
      position: absolute;
      top: 30px;
      right: -210px;
      animation: fingerHandle 1s ease infinite both;
      width: 199px;
      height: 118px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    @keyframes fingerHandle {
      0% { transform: translateX(0%); }
      10% { transform: translateX(10%); }
      20% { transform: translateX(20%); }
      30% { transform: translateX(30%); }
      40% { transform: translateX(40%); }
      50% { transform: translateX(50%); }
      60% { transform: translateX(40%); }
      70% { transform: translateX(30%); }
      80% { transform: translateX(20%); }
      90% { transform: translateX(10%); }
      100% { transform: translateX(0%); }
    }
  }
}
</style>