var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TopicHearingAnswer"},[_c('h1',{staticClass:"TopicHearingAnswer-h1"},[_vm._v(" "+_vm._s(_vm.dataForm.group_title)+". 聽力（"+_vm._s(_vm.partTitle)+"） ")]),_c('div',{staticClass:"TopicHearingAnswer-box"},[(_vm.topicType == 5)?_c('div',{ref:"topicRef1",staticClass:"box-left topic1"},[_c('img',{staticClass:"box-left-img",attrs:{"src":_vm.topicData.image_uri,"alt":""}}),_c('div',{staticClass:"box-left-audio"},[_c('div',{staticClass:"audio-time"},[_c('span',[_vm._v(_vm._s(_vm.audioCurrent))]),_c('span',[_vm._v(_vm._s(_vm.audioTotal))])]),_c('div',{staticClass:"audio-progress"},[_c('el-slider',{attrs:{"show-tooltip":false,"disabled":""},model:{value:(_vm.audioPlan),callback:function ($$v) {_vm.audioPlan=$$v},expression:"audioPlan"}}),_c('audio',{ref:"singeBox"})],1)])]):_vm._e(),(_vm.topicType == 3)?_c('div',{ref:"topicRef2",staticClass:"box-left topic2"},[_c('div',{staticClass:"box-left-audio"},[_c('div',{staticClass:"audio-time"},[_c('span',[_vm._v(_vm._s(_vm.audioCurrent))]),_c('span',[_vm._v(_vm._s(_vm.audioTotal))])]),_c('div',{staticClass:"audio-progress"},[_c('el-slider',{attrs:{"show-tooltip":false,"disabled":""},model:{value:(_vm.audioPlan),callback:function ($$v) {_vm.audioPlan=$$v},expression:"audioPlan"}}),_c('audio',{ref:"singeBox"})],1)])]):_vm._e(),(_vm.answerType == 1)?_c('div',{ref:"answerRef1",staticClass:"box-right answer1"},_vm._l((_vm.topicData.option),function(item,index){return _c('div',{key:index,class:{
          'right-box': true,
          'active': _vm.answerCheck == item.option_title,
        },on:{"click":function($event){return _vm.typeCheck(item.option_title)}}},[_c('span',[_vm._v(_vm._s(item.option_title))]),(item.show_type == 1 && item.content)?_c('p',{domProps:{"innerHTML":_vm._s(item.content)}}):_vm._e()])}),0):_vm._e(),(_vm.answerType == 2)?_c('div',{ref:"answerRef3",staticClass:"box-right answer2"},[_c('div',_vm._l((_vm.topicData.option),function(item,index){return _c('div',{key:index,class:{
            'right-box': true,
            'active': _vm.answerCheck == item.option_title,
          },on:{"click":function($event){return _vm.typeCheck(item.option_title)}}},[_c('span',[_vm._v(_vm._s(item.option_title))])])}),0)]):_vm._e(),(_vm.answerType == 3)?_c('div',{ref:"answerRef2",staticClass:"box-right answer3"},_vm._l((_vm.topicData.option),function(item,index){return _c('div',{key:index,class:_vm.answerCheck == item.option_title ? 'right-box active' : 'right-box',on:{"click":function($event){return _vm.typeCheck(item.option_title)}}},[_c('div',[_c('img',{attrs:{"src":item.image_uri,"alt":""}})]),_c('span',[_vm._v(_vm._s(item.option_title))])])}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }