<!-- 聽力測驗試題範例 -->
<template>
  <div class="TopicHearingTest">
    <h1 class="TopicHearingTest-h1">
      試題範例
    </h1>
    <div class="TopicHearingTest-div">
      <img :src="dataForm.image_uri" alt="">
      <audio ref="singeBox"></audio>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: 'TopicHearingTest',
  props: {
    dataForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      audioBox: '',
    }
  },
  computed: {
    ...mapGetters(['problemPopupStatus']),
  },
  watch: {
    dataForm: {
      handler() {
        this.$nextTick(() => {
          this.audioInit()
        })
      },
      deep: true,
      immediate: true
    },
    
    // 監聽問題回報彈窗 同步暫停/開啟音頻
    problemPopupStatus(val) {
      if (val) {
        this.audioBox.pause()
      } else {
        this.audioBox.play()
      }
    }
  },
  methods: {
    // 播放初始化
    init() {
      this.$nextTick(() => {
        this.audioBox.play()
      })
    },
    // 音頻初始化
    audioInit() {
      this.audioBox = this.$refs.singeBox
      this.audioBox.src = this.dataForm.audio_uri
      const _that = this
      // 当时长有变化时触发，由"NaN"变为实际时长也算
      this.audioBox.ondurationchange = function() {
        console.log('时长发生了变化')
      }
      // 当前数据可用是触发
      this.audioBox.oncanplay = function() {
        console.log('已经可以播放了')
      }
      // 播放位置发送改变时触发。
      this.audioBox.ontimeupdate = function() {
        console.log('播放位置发送了变动')
      }
      // 音频播放完毕
      this.audioBox.onended = function() {
        console.log('播放完毕，谢谢收听')
        _that.$emit('playChange')
      }
      // 音频播放完毕
      this.audioBox.onerror = function() {
        console.log('加载出错！')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.TopicHearingTest {
  width: 100%;
  .TopicHearingTest-h1 {
    padding-bottom: 10px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    color: #0C4FA2;
  }
  .TopicHearingTest-div {
    width: 100%;
    height: calc(100% - 45px);
    overflow-x: auto;
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>